import React, { useEffect, useState } from "react";
import {
  chipValue,
  requestStyle,
  quoteChipColor,
  chipColor,
  chipStyle,
} from "../data/styleData";
import {
  Button,
  Chip,
  useDisclosure,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  Select,
  SelectItem,
  User,
} from "@nextui-org/react";
// import { Button, Chip,User } from "@nextui-org/react";
import { useSelector, useDispatch } from "react-redux";
import UpdateLayout from "./UpdateLayout";
import { toast } from "react-toastify";
import axios from "axios";
import { getQuoteById } from "../store/slices/customerSlice";
import { ChatBox } from "../routes";
import TransactionDetails from "../components/TransactionDetails";

const AcceptQuoteLayout = ({ handleOrder, request, bill }) => {
  const [showAccountConfirmation, setShowAccountConfirmation] = useState(false);
  const role = localStorage.getItem("role");
  const dipatch = useDispatch();
  const [bills, setBills] = useState({})
  const customers = JSON.parse(localStorage.getItem("customer_request"));
  const accessToken = localStorage.getItem("accessToken");
  const [customerDetails, setCustomerDetails] = useState([]);
  const {
    supplier_id,
    quote,
    reps,
    orderDetails,
    singleRequest,
    user,
    quote_images,
  } = useSelector((state) => state.customer);

  const {
    isOpen: paymentOpen,
    onOpen: onPayment,
    onClose: paymentClose,
  } = useDisclosure();

  // console.log(singleRequest)
  const getCustomerDetails = async () => {
    try {
      let res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/users/profile/${singleRequest?.customer_id}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setCustomerDetails(res.data?.details);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAccountClick = () => {
    setShowAccountConfirmation(true);
  };

  const handleConfirmationYes = () => {
    handelCapriconOrAccountPayment(2);
    setShowAccountConfirmation(false);
  };

  const handleConfirmationNo = () => {
    setShowAccountConfirmation(false);
  };

  function recieptList(quote) {
    let freight_price = quote.freight_price_price || 0;
    let part_price = quote.part_price || 0;
    let warranty_price = quote.warranty_price || 0;

    const recieptObj = {};
    const quantity = request?.quantity || 1; // Default quantity if not present
    const totalPrice = freight_price + part_price * quantity + warranty_price;
    const applyTax = quote.applyTax;;
    const taxRate = 10 / 100; // 10% tax rate

    if (applyTax) {
      // Tax is applied separately
      recieptObj.totalTax = Number((totalPrice * taxRate).toFixed(2)); // Calculate tax
      recieptObj.freightPrice = freight_price; // Prices without tax
      recieptObj.partPrice = part_price;
      recieptObj.warrantyPrice = warranty_price;
      recieptObj.totalPrice = Number(
        (totalPrice + (totalPrice * 10) / 100).toFixed(2)
      ); // Total without tax
    } else {
      // Tax is already included in the price, deduct it from each
      recieptObj.totalTax = Number(
        (totalPrice - (totalPrice * 10) / 11).toFixed(2)
      ); // Calculate total tax
      recieptObj.freightPrice = Number(
        (freight_price / (1 + taxRate)).toFixed(2)
      ); // Deduct tax from each price
      recieptObj.partPrice = Number((part_price / (1 + taxRate)).toFixed(2));
      recieptObj.warrantyPrice = Number(
        (warranty_price / (1 + taxRate)).toFixed(2)
      );
      recieptObj.totalPrice = Number(totalPrice); // Total after tax deduction
    }

    return recieptObj;
  }

  const handleStripePayment = async () => {
    try {
      let res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/payment/checkout/${quote?.id}/3`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const paymentLink = res.data.paymentLink;
      paymentClose();
      window.open(paymentLink, "_blank");
    } catch (error) {
      const err = error?.response?.data?.message;
      toast.error(err);
    }
  };

  const handelCapriconOrAccountPayment = async (method, capricornNumber) => {
    try {
      paymentClose();
      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/payment/checkout/${quote?.id}/${method}`,
        { capricornNumber: capricornNumber },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      // await getCustomerDetails();
      // handleOrder();
      dipatch(getQuoteById(quote?.id));//For rerendering the page with updated fields
    } catch (error) {
      const err = error?.response?.data?.message;
      toast.error(err);
    }
  };

  const handelRevertPayment = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/payment/${quote?.id}/status/revert`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      dipatch(getQuoteById(quote?.id));
      // getCustomerDetails();
    } catch (error) {
      const err = error?.response?.data?.message;
      toast.error(err);
    }
  };

  const handelConfirmPayment = async () => {
    const apiUrl = (role == 'CUSTOMER' 
      ? `${process.env.REACT_APP_SERVER_URL}/payment/${quote?.id}/status/confirmed` 
      : `${process.env.REACT_APP_SERVER_URL}/payment/${quote?.id}/status/paid`);
      
      try {
        await axios.post(
          apiUrl,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        dipatch(getQuoteById(quote?.id));
      } catch (error) {
        const err = error?.response?.data?.message;
        toast.error(err);
      }
  };

  useEffect(() => {
    getCustomerDetails();
    if(Object.keys(quote).length > 0){
      const bill = recieptList(quote);
      console.log(bill)
      setBills(bill)
    }
    if (orderDetails) {

      let status = orderDetails?.OrderStatus?.status;
      if (status === "Dispute") {
        toast.error("Your order has been disputed!");
      }
    } else {
      if (role === "CUSTOMER") {
        toast.info("Order is not generated by supplier");
      }
    }
  }, [supplier_id, quote, orderDetails, singleRequest, user, quote_images]);


  return (
    <div>
      {quote?.Payment?.paymentMethod && (
        <Chip
          color="secondary"
          variant="dot"
          className="float-right text-purple-400 border-[2px] border-purple-400 ml-2"
        >
          <p>{quote.Payment.paymentMethod}</p>
        </Chip>
      )}
      {quote?.Payment?.status === "Paid" ? (
        <>
          <Chip
            color={chipColor["Accepted"]} // Assuming "Accepted" corresponds to green color
            variant="dot"
            className="float-right text-green-400 border-[2px] border-green-400"
          >
            <p>Paid</p>
          </Chip>
        </>
      ) : (
        <Chip
          color={chipColor["Rejected"]}
          variant="dot"
          className="float-right text-red-400 border-[2px] border-red-400"
        >
          <p>{quote?.Payment?.status || "Unpaid"}</p>
        </Chip>
      )}

      <UpdateLayout
        heading={"Accepted Quote"}
        title={""}
        images={quote_images}
        requestId={quote?.request_id}
        quoteId={quote?.id}
      >
        {/* --------customer details------- */}
        {singleRequest?.RequestStatus?.status === "Accepted" && quote?.Payment && (quote?.Payment?.status === 'Unpaid' || quote?.Payment?.status === 'Reverted' || quote?.Payment?.status === 'Confirmed') && (
          <div className="mb-5">
            <div className="flex justify-between items-center">
              {/* <p className="font-semibold">Customer Details</p>
              {<Button
                className="border-2 bg-transparent hover:bg-purpleBtn hover:text-white font-semibold border-purpleBtn text-purpleBtn mt-2"
                onClick={handelConfirmPayment}
              >
                Confirm Payment
              </Button>} */}
              <p className="font-semibold">Customer Details</p>

              {(role === "CUSTOMER" && quote?.Payment?.status === "Unpaid") ||
                (role === "SUPPLIER" && 
                  (quote?.Payment?.status === "Unpaid" || quote?.Payment?.status === "Confirmed")) ? (
                    <Button
                      className="border-2 bg-transparent hover:bg-purpleBtn hover:text-white font-semibold border-purpleBtn text-purpleBtn mt-2"
                      onClick={handelConfirmPayment}
                    >
                      Confirm Payment
                    </Button>
                ) : null}
            </div>
            <div className="flex gap-4 mt-8">
              <User
                // name={customerDetails?.user?.first_name}
                description=""
                avatarProps={{
                  src: `${customerDetails?.user?.profile_id}`,
                }}
                className="font-semibold"
              />
              <div>
                <p>
                  {customerDetails?.user?.first_name}{" "}
                  {customerDetails?.user?.last_name}
                </p>
                <p className="text-sm">{customerDetails?.user?.email}</p>
              </div>
              <p>
                {customerDetails?.user?.capricorn_number &&
                  `(Capricorn Member: ${customerDetails?.user?.capricorn_number} )`}
              </p>
            </div>
            <div className="grid grid-cols-4 gap-2 [&>div]:text-center [&>div]:text-[0.8rem] [&>div>p:first-child]:font-semibold [&>div>p:first-child]:text-[0.9rem] mt-7">
              <div>
                <p>Phone no.</p>
                <p>{customerDetails?.user?.phone}</p>
              </div>
              <div>
                <p>City</p>
                <p>{customerDetails?.user?.suburb}</p>
              </div>

              <div>
                <p>State</p>
                <p>{customerDetails?.user?.state}</p>
              </div>
              <div>
                <p>Postcode</p>
                <p>{customerDetails?.user?.postcode}</p>
              </div>
            </div>
          </div>
        )}

        {/* --------request details------- */}
        <div>
          <div className="flex justify-between items-center">
            <p className="font-semibold">Request Details</p>
            <Chip
              color={chipColor[singleRequest?.RequestStatus?.status]}
              variant="dot"
              className={chipStyle[singleRequest?.RequestStatus?.status]}
            >
              {<p>{singleRequest?.RequestStatus?.status}</p>}
            </Chip>
          </div>
          <div className="grid grid-cols-2 sm:grid-cols-4 gap-2 [&>div]:text-center [&>div]:text-[0.8rem] [&>div>p:first-child]:font-semibold [&>div>p:first-child]:text-[0.9rem] mt-7">
            <div>
              <p>Vehicle Make</p>
              <p>
                {singleRequest?.VehicleMake?.name
                  ? singleRequest?.VehicleMake?.name
                  : singleRequest?.custom_make}
              </p>
            </div>
            <div>
              <p>Vehicle Mode</p>
              <p>
                {singleRequest?.VehicleModel?.name
                  ? singleRequest?.VehicleModel?.name
                  : singleRequest?.custom_model}
              </p>
            </div>
            <div>
              <p>Vehicle Part</p>
              <p>
                {singleRequest?.Part
                  ? singleRequest?.Part?.name
                  : singleRequest?.custom_part_type}
              </p>
            </div>
            <div>
              <p>Quantity</p>
              <p>{singleRequest?.quantity} Qnty</p>
            </div>
            <div>
              <p>Rego</p>
              <p>{singleRequest?.rego}</p>
            </div>
            <div>
              <p>Fuel Type</p>
              <p>{singleRequest?.fuelType?.name}</p>
            </div>
            <div>
              <p>Transmission</p>
              <p>{singleRequest?.transmissionModel?.name}</p>
            </div>
            <div>
              <p>Body Style</p>
              <p>{singleRequest?.bodyStyle?.name}</p>
            </div>
            <div className="col-span-2 sm:col-span-4 !text-left ml-4">
              <span className="text-[0.9rem] font-semibold text-left">
                Interchange Number:{" "}
              </span>
              <span>
                {singleRequest?.interchange_number
                  ? singleRequest?.interchange_number
                  : "Not Provided"}
              </span>
            </div>
            <div className="col-span-2 sm:col-span-4 !text-left ml-4">
              <span className="text-[0.9rem] font-semibold text-left">
                Note:{" "}
              </span>
              <span>{singleRequest?.notes}</span>
            </div>
          </div>
        </div>
        {/* -------quote details------- */}
        <div>
          <div className="flex justify-between mt-10">
            <p className="font-semibold">Quote Details:</p>
            <Chip
              color={quoteChipColor[quote?.status]}
              variant="dot"
              className={requestStyle[quote?.status]}
            >
              <p>{chipValue[quote?.status]}</p>
            </Chip>
          </div>
          <div className="grid grid-cols-3 gap-2 [&>div]:text-center [&>div]:text-[0.8rem] [&>div>p:first-child]:font-semibold [&>div>p:first-child]:text-[0.9rem] mt-7">
            <div>
              <p>Part Price</p>
              <p>${quote?.part_price}</p>
            </div>
            <div>
              <p>Freight Price</p>
              <p>${quote?.freight_price}</p>
            </div>
            <div>
              <p>Total Price</p>
              <p>${quote?.total_price}</p>
            </div>
            <div>
              <p>Sales Rep</p>
              <p>{quote?.SupplierSalesRep?.SalesRepName}</p>
            </div>

            <div>
              <p>Warranty</p>
              <p>
                {quote?.warrantyDuration?.duration}{" "}
                {quote?.warrantyDuration?.unit}
              </p>
            </div>

            <div>
              <p>Warranty Option</p>
              <p>
                {quote?.warranty_option
                  ? quote?.warranty_option
                  : "Not Provided"}
              </p>
            </div>

            <div>
              <p>Warranty Price</p>
              <p>${quote?.warranty_price ? quote?.warranty_price : "0"}</p>
            </div>

            <div>
              <p>Shipping Date</p>
              <p>{quote?.shipping_date?.split("T")[0]}</p>
            </div>

            <div className="col-span-3 ml-4">
              <span className="text-[0.9rem] font-semibold">Note: </span>
              <span>{quote?.notes}</span>
            </div>
          </div>
        </div>
        {/*transaction  */}
        <TransactionDetails request={quote.Request} bill={bill!==undefined ? bill : bills} />
        <div className="mt-7">
          {role === "SUPPLIER" ? (
            <div>
              <Button
                className="w-full border-2 bg-transparent hover:bg-lightBlue hover:text-white font-semibold border-lightBlue text-lightBlue"
                onClick={handleOrder}
              >
                {quote?.orderDetails?.OrderStatus?.status === "Initiated"
                  ? "Order already exist"
                  : "Generate Order"}
              </Button>
              {quote?.Payment?.paymentMethod !== "Stripe" &&
                (quote?.Payment?.status === "Unpaid" || quote?.Payment?.status === 'Confirmed') && (
                  <Button
                    className="w-full border-2 bg-transparent hover:bg-purpleBtn hover:text-white font-semibold border-purpleBtn text-purpleBtn mt-2"
                    onClick={handelRevertPayment}
                  >
                    Revert Payment
                  </Button>
                )}
            </div>
          ) : (
            ( quote?.Payment == null ||
              (quote?.Payment?.paymentMethod === "Stripe" && quote?.Payment?.status === "Unpaid") ||
              quote?.Payment?.status === "Reverted"
            ) && (
              <Button
                className="w-full border-2 bg-transparent hover:bg-lightBlue hover:text-white font-semibold border-lightBlue text-lightBlue"
                // onClick={handlePayment}
                onClick={onPayment}
              >
                Checkout for Payment{" "}
              </Button>
            )
          )}
        </div>
        
      </UpdateLayout>
      <Modal
        size={"sm"}
        isOpen={paymentOpen}
        onClose={paymentClose}
        className="p-3"
        placement="center"
      >
        {/* <ModalContent>
            {(paymentClose) => (
              <>
                <>
                  <ModalBody className="w-full">
                    <p className="text-base font-semibold text-center">
                      Select a payment method
                    </p>
                  </ModalBody>
                  <ModalFooter className="p-3 mt-4 justify-normal">
                    <div className="grid grid-cols-1 gap-4 w-full">
                      <Button
                        className="w-full bg-purpleBtn font-semibold text-white"
                        onClick={handleStripePayment}
                      >
                        Online/Card
                      </Button>
                      {customerDetails?.user?.capricorn_number && <Button
                        type="submit"
                        className="w-full bg-purpleBtn font-semibold text-white"
                        onClick={() => handelCapriconOrAccountPayment(1, customerDetails?.user?.capricorn_number)}
                        disabled={customerDetails?.user?.capricorn_number ? false : true}
                      >
                        Capricorn
                      </Button>}
                      <Button
                        type="submit"
                        className="w-full bg-purpleBtn font-semibold text-white"
                        onClick={() => handelCapriconOrAccountPayment(2)}
                      >
                        Account 
                      </Button>
                    </div>
                  </ModalFooter>
                </>
              </>
            )}
          </ModalContent> */}
        <ModalContent>
          {(paymentClose) => (
            <>
              <ModalBody className="w-full">
                <p className="text-base font-semibold text-center">
                  Select a payment method
                </p>
              </ModalBody>
              <ModalFooter className="p-3 mt-4 justify-normal">
                <div className="grid grid-cols-1 gap-4 w-full">
                  {/* Original Payment Options */}
                  {!showAccountConfirmation ? (
                    <>
                      <Button
                        className="w-full bg-purpleBtn font-semibold text-white"
                        onClick={handleStripePayment}
                      >
                        Online/Card
                      </Button>

                      {/* Conditionally render Capricorn button if user has a capricorn number */}
                      {customerDetails?.user?.capricorn_number && (
                        <Button
                          type="submit"
                          className="w-full bg-purpleBtn font-semibold text-white"
                          onClick={() =>
                            handelCapriconOrAccountPayment(
                              1,
                              customerDetails?.user?.capricorn_number
                            )
                          }
                        >
                          Capricorn
                        </Button>
                      )}

                      {/* Account button */}
                      <Button
                        type="submit"
                        className="w-full bg-purpleBtn font-semibold text-white"
                        onClick={handleAccountClick}
                      >
                        Account
                      </Button>
                    </>
                  ) : (
                    // Confirmation Box when Account is clicked
                    <>
                      <p className="text-base font-semibold text-center">
                        Do you have an account with the supplier?
                      </p>
                      <div className="flex justify-between w-full">
                        <Button
                          className="bg-lightBlue w-full mr-2 text-white"
                          onClick={handleConfirmationYes}
                        >
                          Yes
                        </Button>
                        <Button
                          className="bg-purpleBtn w-full ml-2 text-white"
                          onClick={handleConfirmationNo}
                        >
                          No
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
      <ChatBox quoteId={quote?.id}></ChatBox>
    </div>
  );
};

export default AcceptQuoteLayout;
